import { createSlice } from '@reduxjs/toolkit';
import {
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from 'agora-rtc-sdk-ng';
import { isStepsUnfinished } from './utils/helpers';
import {
  INTVIEW_STATUS_INIT,
  INTVIEW_STATUS_PREPARE,
  INTVIEW_STATUS_START,
  INTVIEW_STATUS_VIDEO_BROADCAST,
} from './constant';

export interface ILocalTracks {
  videoTrack: ICameraVideoTrack | null;
  audioTrack: IMicrophoneAudioTrack | null;
}

export interface IRemoteUsers {
  [key: string]: IAgoraRTCRemoteUser;
}

interface IPositionInfo {
  application: {
    isEnd: boolean;
    process: {
      applicationProcessToken: string;
      duration: number;
      name: string;
      remainTime: number;
      singleInterviewFlag: boolean;
      status: number;
      statusName: string;
      token: string;
      type: number;
    };
    token: string;
  };
  position: {
    cover: {
      defaultVideoUrl: string;
      height: number;
      shareCoverImg: string;
      videoUrl: string;
      width: number;
    };
    department: {
      name: string;
    };
    desc: string;
    distance: number;
    enterprise: {
      logo: string;
      name: string;
    };
    latitude: number;
    location: {
      area: string;
      city: string;
      detail: string;
      extra: string;
      province: string;
      type: number;
    };
    longitude: number;
    name: string;
    parentShareToken: string;
    positionBackgroundImage: string;
    recommendStatus: number;
    requirement: {
      workExperience: number;
      degree: number;
      detail: string;
    };
    salary: {
      from: number;
      to: number;
      type: number;
    };
    shareInfo: {
      coverUrl: string;
      qrCodeUrl: string;
      title: string;
    };
    token: string;
    treatments: string[];
    qrcodeUrl: string;
  };
}

interface IInterviewStep {
  answerRemainTimes: number;
  answerStatus: number;
  patternToken: string;
  retryRemainTimes: number;
  status: number;
  stepToken: string;
  isFinish: boolean;
}

export interface IReusedVideoItem {
  answerStepToken: string;
  mediaUrl: string;
  patternDesc: string;
  patternID: number;
  patternName: string;
  positionName: string;
  stepToken: string;
}

export interface ISelectedReusedVideoItem {
  stepToken: string;
  answerStepToken: string;
}

interface RoomState {
  enterprise: {
    logo: string;
    name: string;
  };
  page: 0 | 1 | 2 | 3;
  isAllFinished: boolean;
  localUserId: string;
  positionInfo: IPositionInfo;
  step: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  processType: number;
  processType2Step: number;
  applicationToken: string;
  nextProcess: {
    isEnd: boolean;
    aiInterviewRemainNum: number;
    position: {
      positionName: string;
      name: string;
      qrCode: string;
      isSimulated: number;
    };
    process: {
      token: string;
      processToken: string;
      type: number;
      name: string;
      status: number;
      statusName: string;
      duration: number;
      remainTime: number;
      applicationProcessToken: string;
      singleInterviewFlag: boolean;
      isExpire: boolean;
      expireTime: number;
      videos: {
        type: number;
        pcUrl: string;
        url: string;
        thumbUrl: string;
        guideVideoType: number;
      }[];
      cheatKey: string;
      resultStylePage: number;
      canRepeatInterview: number;
      deadlineTime: number;
      completeTime: number;
      showInterviewCode: number;
      interviewCode: string;
      idVerifyResult: {
        faceImg: string;
        name: string;
        idNumber: string;
      };
      interviewToken: string;
    };
  };
  processToken: string;
  interviewInfo: {
    aiModelToken: string;
    aiModelName: string;
    aiModelDesc: string;
    aiInterviewStatus: number;
    questionCount: number;
    estimatedTime: number;
    expiredTime: number;
    isCheat: boolean;
    interactType: number;
    virtualManMedia: string;
    virtualManMediaWide: string;
    xmSoundUrl: string;
    aiVideoStatus: number;
    aiInterviewerProfile: { humanType: number; realHumanType: number };
    duration: number;
    interaction: any;
    interviewerModeConfig: {
      videoURL: string; // 标准视频 URL
      videoURLWide: string; // 宽屏视频 URL (可选)
      ranges: {
        speaking: number[];
        listening: number[];
      }; // 视频范围定义
    };
  };
  progressInfo: {
    positionName: string;
    interviewSteps: IInterviewStep[];
  };
  reusedVideoList: IReusedVideoItem[];
  selectedReusedVideoList: ISelectedReusedVideoItem[];
  stepIndex: number;
  stepInfo: {
    stepToken: string;
    questionToken: string;
    questionName: string;
    desc: string;
    prepareTime: number;
    minResponseDuration: number;
    duration: number;
    extra: any;
    retryRemainTimes: number;
    soundUrl: string;
    type: number;
    streamName: string;
    liveUrl: string;
    pcLiveUrl: string;
    answerRemainTimes: number;
    answerStatus: number;
    aiShowType: number;
    broadcastType: number;
    videoBroadcast: {
      videoUrlWide: string;
      videoUrl: string;
      questionInterviewStage: number;
      tryRemainTimes: number;
      videoSkip: number;
    };
    isFinish: boolean;
    virtualManUrl: string;
    virtualManUrlWide: string;
    isOpenAIFollowUp: boolean;
    options: any;
    isFllowUp: boolean;
  };
  stepToken: string;
  stepProgressPercent: number;
  startedPrepareCountdown: boolean;
  startedDurationCountdown: boolean;
  startedLiveStreaming: boolean;
  rtcInfo: {
    channelName: string;
    rtcToken: string;
  };
  showStepGuideDialog: boolean;
  doNotNeedCountdown: boolean;
  needGameResult: boolean;
  gameScore: Record<string, unknown>;
  gameMemo: Record<string, unknown>;
  examBlured: boolean;
  answer: string;
  shouldRefreshCurrentStep: boolean;
}

const initialState = {
  enterprise: {
    logo: '',
    name: '',
  },
  page: 0,
  isAllFinished: false,
  localUserId: '',
  positionInfo: {
    application: {
      isEnd: false,
      process: {
        applicationProcessToken: '',
        duration: 0,
        name: '',
        remainTime: 0,
        singleInterviewFlag: false,
        status: 0,
        statusName: '',
        token: '',
        type: 0,
      },
      token: '',
    },
    position: {
      cover: {
        defaultVideoUrl: '',
        height: 0,
        shareCoverImg: '',
        videoUrl: '',
        width: 0,
      },
      department: {
        name: '',
      },
      desc: '',
      distance: 0,
      enterprise: {
        logo: '',
        name: '',
      },
      latitude: 0,
      location: {
        area: '',
        city: '',
        detail: '',
        extra: '',
        province: '',
        type: 0,
      },
      longitude: 0,
      name: '',
      parentShareToken: '',
      positionBackgroundImage: '',
      recommendStatus: 0,
      requirement: {
        workExperience: 0,
        degree: 0,
        detail: '',
      },
      salary: {
        from: 0,
        to: 0,
        type: 0,
      },
      shareInfo: {
        coverUrl: '',
        qrCodeUrl: '',
        title: '',
      },
      token: '',
      treatments: [],
      qrcodeUrl: '',
    },
  },
  step: 0,
  processType: 0,
  processType2Step: 0,
  applicationToken: '',
  nextProcess: {
    isEnd: false,
    aiInterviewRemainNum: 0,
    position: {
      positionName: '',
      name: '',
      qrCode: '',
      isSimulated: 0,
    },
    process: {
      token: '',
      processToken: '',
      type: 0,
      name: '',
      status: 0,
      statusName: '',
      duration: 0,
      remainTime: 0,
      applicationProcessToken: '',
      singleInterviewFlag: false,
      isExpire: false,
      expireTime: 0,
      videos: [],
      cheatKey: '',
      resultStylePage: 0,
      canRepeatInterview: 0,
      deadlineTime: 0,
      completeTime: 0,
      showInterviewCode: 0,
      interviewCode: '',
      idVerifyResult: {
        faceImg: '',
        name: '',
        idNumber: '',
      },
      interviewToken: '',
    },
  },
  processToken: '',
  interviewInfo: {
    aiModelToken: '',
    aiModelName: '',
    aiModelDesc: '',
    aiInterviewStatus: 0,
    questionCount: 0,
    estimatedTime: 0,
    expiredTime: 0,
    isCheat: false,
    interactType: 0,
    virtualManMedia: '',
    virtualManMediaWide: '',
    xmSoundUrl: '',
    aiVideoStatus: 0,
    aiInterviewerProfile: { humanType: 0, realHumanType: 0 },
    duration: 0,
    interaction: {},
    interviewerModeConfig: {
      videoURL: '',
      videoURLWide: '',
      ranges: {
        speaking: [],
        listening: [],
      },
    },
  },
  progressInfo: {
    positionName: '',
    interviewSteps: [],
  },
  reusedVideoList: [],
  selectedReusedVideoList: [],
  stepIndex: 0,
  stepInfo: {
    stepToken: '',
    questionToken: '',
    questionName: '',
    desc: '',
    prepareTime: 0,
    minResponseDuration: 0,
    duration: 0,
    extra: {},
    retryRemainTimes: 0,
    soundUrl: '',
    type: 0,
    streamName: '',
    liveUrl: '',
    pcLiveUrl: '',
    answerRemainTimes: 0,
    answerStatus: 0,
    aiShowType: 0,
    broadcastType: 0,
    videoBroadcast: {
      videoUrlWide: '',
      videoUrl: '',
      questionInterviewStage: 0,
      tryRemainTimes: 0,
      videoSkip: 0,
    },
    isFinish: false,
    virtualManUrl: '',
    virtualManUrlWide: '',
    isOpenAIFollowUp: false,
    options: [],
    isFllowUp: false,
  },
  stepToken: '',
  stepProgressPercent: 0,
  startedPrepareCountdown: false,
  startedDurationCountdown: false,
  startedLiveStreaming: false,
  rtcInfo: {
    channelName: '',
    rtcToken: '',
  },
  showStepGuideDialog: false,
  doNotNeedCountdown: false,
  needGameResult: false,
  gameScore: {},
  gameMemo: {},
  examBlured: false,
  answer: '',
  stepTokenCount: 0,
  shouldRefreshCurrentStep: false,
} as RoomState;

const slice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setLocalUserId: (state, action) => {
      state.localUserId = action.payload;
    },
    setPositionInfo: (state, action) => {
      state.positionInfo = action.payload;
    },
    nextStep: (state) => {
      const {
        broadcastType,
        videoBroadcast: { tryRemainTimes },
        isFllowUp,
      } = state.stepInfo;
      if (state.step === INTVIEW_STATUS_INIT && isFllowUp) {
        state.step = INTVIEW_STATUS_START;
      } else if (
        state.step === INTVIEW_STATUS_INIT &&
        ([1, 3].includes(broadcastType) || state.stepInfo.type === 17)
      ) {
        state.step = INTVIEW_STATUS_PREPARE;
      } else if (state.step === INTVIEW_STATUS_VIDEO_BROADCAST) {
        state.step = INTVIEW_STATUS_PREPARE;
      } else {
        state.step += 1;
      }
      console.log(state.step, tryRemainTimes);
    },
    initialStep: (state) => {
      state.step = 0;
    },
    processType2NextStep: (state) => {
      state.processType2Step += 1;
    },
    setApplicationToken: (state, action) => {
      state.applicationToken = action.payload;
    },
    setNextProcess: (state, action) => {
      state.nextProcess = action.payload;
      state.processType = action.payload?.process?.type ?? 0;
    },
    setProcesType: (state, action) => {
      state.processType = action.payload;
    },
    setProcessToken: (state, action) => {
      // const { interactType, aiVideoStatus } = action.payload;
      // if (interactType === 2 && aiVideoStatus === 2) {
      // }
      state.processToken = action.payload;
    },
    setInterviewInfo: (state, action) => {
      console.log(action.payload);
      state.interviewInfo = action.payload;
    },
    setProgressInfo: (state, action) => {
      const { interviewSteps = [] } = action.payload;
      const stepIndex = interviewSteps.findIndex((s) => isStepsUnfinished(s));
      if (stepIndex > -1) {
        state.stepIndex = stepIndex;
        const step = interviewSteps[stepIndex];
        state.stepToken = step.stepToken;
        state.stepProgressPercent = Math.floor(
          (((stepIndex + 1) * 100.0) / interviewSteps.length) * 1.0
        );
        state.stepInfo = { ...state.stepInfo, ...step };
      } else {
        state.isAllFinished = true;
      }
      state.step = 0;
      state.progressInfo = action.payload;
    },
    setRTCInfo: (state, action) => {
      state.rtcInfo = action.payload;
    },
    setReusedVideoList: (state, action) => {
      state.reusedVideoList = action.payload;
    },
    setSelectedReusedVideoList: (state, action) => {
      const { stepToken } = action.payload;
      const index = state.selectedReusedVideoList.findIndex(
        (p) => p.stepToken === stepToken
      );
      if (index > -1) {
        state.selectedReusedVideoList.splice(index, 1);
      } else {
        state.selectedReusedVideoList.push(action.payload);
      }
    },
    selectedAllReusedVideoList: (state) => {
      state.selectedReusedVideoList = state.reusedVideoList.map(
        ({ stepToken, answerStepToken }) => ({ stepToken, answerStepToken })
      );
    },
    selectedNoneReusedVideoList: (state) => {
      state.selectedReusedVideoList = [];
    },
    setStepInfo: (state, action) => {
      state.stepInfo = action.payload;
      const { virtualManUrlWide, virtualManUrl } = action.payload;
      if (
        (virtualManUrlWide || virtualManUrl) &&
        state.stepInfo.broadcastType === 1 &&
        state.interviewInfo?.aiInterviewerProfile?.humanType === 2
      ) {
        state.stepInfo.videoBroadcast.videoUrlWide =
          virtualManUrlWide || virtualManUrl;
        state.stepInfo.broadcastType = 3;
      }
      if (state.stepInfo.prepareTime < 3) state.stepInfo.prepareTime = 3;
      state.startedPrepareCountdown = false;
      state.startedDurationCountdown = false;
      state.startedLiveStreaming = false;
      state.needGameResult = [7, 8, 9].includes(action.payload.type);
      state.showStepGuideDialog = [7, 8, 9].includes(action.payload.type);
      state.doNotNeedCountdown = [7, 8, 9].includes(action.payload.type);
    },
    setFollowUpStepInfo: (state, action) => {
      const { desc, soundUrl } = action.payload;
      state.stepInfo.desc = desc;
      state.stepInfo.soundUrl = soundUrl;
      state.stepInfo.isFllowUp = true;
      state.step = INTVIEW_STATUS_INIT;
    },
    setStepToken: (state, action) => {
      state.stepToken = action.payload;
    },
    setStartedPrepareCountdown: (state, action) => {
      state.startedPrepareCountdown = action.payload;
    },
    setStartedDurationCountdown: (state, action) => {
      state.startedDurationCountdown = action.payload;
    },
    setStartedLiveStreaming: (state, action) => {
      state.startedLiveStreaming = action.payload;
    },
    setShowStepGuideDialog: (state, action) => {
      state.showStepGuideDialog = action.payload;
    },
    setGameResult: (state, action) => {
      const { score, memo } = action.payload;
      state.gameScore = score;
      state.gameMemo = memo;
    },
    setExamBlured: (state, action) => {
      state.examBlured = action.payload;
      state.startedPrepareCountdown = false;
      state.startedDurationCountdown = false;
      state.startedLiveStreaming = false;
      if (state.needGameResult) {
        state.showStepGuideDialog = true;
      }
    },
    setAnswer: (state, action) => {
      state.answer = action.payload;
    },
    setShouldRefreshCurrentStep: (state, action) => {
      state.shouldRefreshCurrentStep = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setEnterprise: (state, action) => {
      state.enterprise = action.payload;
    },
    setInitialState: (state) => {
      state.nextProcess = initialState.nextProcess;
      state.applicationToken = initialState.applicationToken;
      state.step = initialState.step;
      state.page = initialState.page;
      state.progressInfo = initialState.progressInfo;
      state.isAllFinished = initialState.isAllFinished;
      state.stepIndex = initialState.stepIndex;
      state.stepInfo = initialState.stepInfo;
      state.stepToken = initialState.stepToken;
      state.stepProgressPercent = initialState.stepProgressPercent;
      state.startedPrepareCountdown = initialState.startedPrepareCountdown;
      state.startedDurationCountdown = initialState.startedDurationCountdown;
      state.startedLiveStreaming = initialState.startedLiveStreaming;
      state.rtcInfo = initialState.rtcInfo;
    },
  },
});

export const interviewActions = slice.actions;
export const interviewReducer = slice.reducer;

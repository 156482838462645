import { useAppSelector } from '../../../redux/hooks';
import { INTERVIEW_TYPE } from '../utils/Interview';

const useInterviewType = () => {
  const { interviewInfo } = useAppSelector((state) => state.interview);
  const { broadcastType, type } = useAppSelector(
    (state) => state.interview.stepInfo
  );
  if (broadcastType === 2 || type === 17) {
    return 'normal';
  }
  const { interaction, interactType } = interviewInfo || {};
  const interviewType = INTERVIEW_TYPE(
    interactType,
    interaction?.interviewerType
  );
  return interviewType;
};

export default useInterviewType;

import { Message } from 'fbm-ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { canMobileLogin } from './constant';
import CommonAPI from '../../api/common';
import { showErrorMessage } from '../../utils/apiCheck';

const useReminder = () => {
  const navigate = useNavigate();
  const [autoLogout, setAutoLogout] = useState({
    autoLogoutOpen: 2,
    logoutTime: 0,
    logoutWarnTime: 0,
  });

  const [mousePosition, setMousePosition] = useState('00');

  const handleMouseMove = (event) => {
    setMousePosition(`${event.clientX}${event.clientY}`);
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);

    // 清除事件监听器
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('id_token');
    if (token) {
      CommonAPI.getSimulateSettings()
        .then(setAutoLogout)
        .catch(showErrorMessage);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('id_token');
    if (token && autoLogout.autoLogoutOpen === 1) {
      const threeMinuteTimer = setTimeout(() => {
        if (!autoLogout.logoutWarnTime) return;
        Message.warning(
          `您已超过${autoLogout.logoutWarnTime}分钟未进行操作，超过${autoLogout.logoutTime}分钟，会自动退出哦！`
        );
      }, 1000 * 60 * (autoLogout.logoutWarnTime || autoLogout.logoutTime));
      const fiveMinuteTimer = setTimeout(() => {
        localStorage.clear();
        navigate(canMobileLogin() ? '/login' : '/studentlogin');
      }, 1000 * 60 * autoLogout.logoutTime);
      return () => {
        clearTimeout(threeMinuteTimer);
        clearTimeout(fiveMinuteTimer);
      };
    }
  }, [navigate, mousePosition, autoLogout]);
};

export default useReminder;

import { useEffect, useMemo, useRef } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { useInterviewType, useNewVirtualHumanConfig } from './hooks';

interface VideoProps {
  src: string;
  onEnd?: (ev: Event) => any;
  audioUrl?: string;
  action: 'activated' | 'taking' | 'thinking' | 'idle';
}

const Video: React.FC<VideoProps> = ({ src, onEnd, audioUrl, action }) => {
  const interviewType = useInterviewType();
  const interviewerModeConfig = useNewVirtualHumanConfig();
  console.log(
    '>>>>>>>>>action',
    action,
    audioUrl,
    interviewType,
    interviewerModeConfig
  );
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const step = useAppSelector((state) => state.interview.step);
  const isFllowUp = useAppSelector(
    (state) => state.interview.stepInfo.isFllowUp
  );

  const { speaking, listening } = interviewerModeConfig.ranges || {};
  const handleAudioEnded = (event) => {
    if (videoRef.current) {
      videoRef.current.currentTime = speaking[speaking.length - 1];
      videoRef.current?.pause();
    }
    onEnd?.(event);
    console.log('音频播放结束');
  };

  const handleVideoEnded = (event) => {
    if (interviewType !== 'newVirtualHuman') {
      onEnd?.(event);
      console.log('视频播放结束');
    } else {
      if (videoRef.current) {
        videoRef.current.currentTime = listening[0];
        videoRef.current.play();
      }
    }
  };

  useEffect(() => {
    if (
      interviewType === 'newVirtualHuman' &&
      action === 'activated' &&
      videoRef.current?.paused
    ) {
      videoRef.current.play();
    }
  }, [action, interviewType]);

  const videoUrl = useMemo(() => {
    console.log('>>>>>>>>>>>>>', interviewerModeConfig, interviewType, src);
    if (interviewType === 'newVirtualHuman') {
      return interviewerModeConfig?.videoURLWide;
    }
    return src;
  }, [interviewerModeConfig, interviewType, src]);

  const handleTimeUpdate = (event) => {
    if (interviewType !== 'newVirtualHuman') return;

    if (
      videoRef.current &&
      !audioRef.current?.paused &&
      videoRef.current.currentTime > speaking[1]
    ) {
      videoRef.current.currentTime = speaking[0];
      videoRef.current.play();
    }
    console.log('>>>>>>>>>>>>>', videoRef.current.currentTime);
  };

  useEffect(() => {
    if (step === 0 && videoRef.current && !isFllowUp) {
      videoRef.current.play();
    }
  }, [isFllowUp, step]);

  useEffect(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      // 每次 audioUrl 变化时重新加载并播放
      audioElement.load();
      audioElement.play().catch((error) => {
        console.error('播放失败:', error);
      });
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      }
    }
  }, [audioUrl]);

  return (
    <>
      {interviewType === 'newVirtualHuman' && (
        <audio ref={audioRef} id="audio" autoPlay onEnded={handleAudioEnded}>
          <source src={audioUrl} type="audio/mp3" />
          您的浏览器不支持 HTML5 音频。
        </audio>
      )}
      <video
        ref={videoRef}
        src={videoUrl}
        width="100%"
        height="100%"
        autoPlay
        muted={interviewType === 'newVirtualHuman'}
        playsInline
        onEnded={handleVideoEnded}
        onTimeUpdate={handleTimeUpdate}
        style={{ objectFit: 'contain' }}
      />
    </>
  );
};

export default Video;

import { Box, Paper, Typography, Button } from 'fbm-ui';
import { Stack } from 'fbm-ui/lib/mui';
import LoginAPI from '../../api/login';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Layout, useMobileTextField } from 'fbm-ui';
import MobileTextField from './MobileTextField';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@avocadoui/components';
import useMobileCodes from '../../hooks/useMobileCodes';
import * as z from 'zod';
import { showErrorMessage } from '../../utils/apiCheck';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CodeButton from './CodeButton';
import NameDialog from './NameDialog';
import { IS_JIUYEQIAO } from '../../oem';
import { useTranslation } from 'react-i18next';
import LanguageLayout from './LanguageLayout';
import { log } from '@avocadoui/utils';

// Sign in to continue to  Interview

export type FormData = {
  mobile: {
    mobile: string;
    mobileAreaCode?: string;
  };
  code: string;
};

export const schema = z.object({
  mobile: z.object({
    mobile: z.string().min(1),
    mobileAreaCode: z.string(),
  }),
  code: z.string().length(4),
});

const Login = () => {
  const { t } = useTranslation();
  const token = localStorage.getItem('id_token');
  const [open, setOpen] = useState(false);
  const [tempApplicationToken] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [logining, setLogining] = useState(false);

  const resolveRef = useRef<() => void>();

  const { control, getValues, setValue, watch } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      mobile: {
        mobile: '',
        mobileAreaCode: '',
      },
      code: '',
    },
    resolver: zodResolver(schema),
  });

  const loginRedirect = useCallback(() => {
    const redirect = searchParams.get('redirect');
    if (redirect) {
      navigate(redirect);
    } else {
      navigate(`/simulate/list`);
    }
  }, [navigate, searchParams]);

  const { mobileCodes } = useMobileCodes();
  const { mobileTextFieldProps } = useMobileTextField({
    options: mobileCodes,
    defaultMobile: {
      mobile: '',
      mobileAreaCode: '',
    },
    onChange: (val) => {
      setValue('mobile', {
        mobile: val?.mobile,
        mobileAreaCode: getValues().mobile.mobileAreaCode,
      });
    },
    onAreaChange: (val) => {
      setValue('mobile', {
        mobile: getValues().mobile.mobile,
        mobileAreaCode: val,
      });
    },
  });

  const handleLogin = useCallback(() => {
    setLogining(true);
    const { mobile, mobileAreaCode = '' } = getValues('mobile');
    const code = getValues('code');
    LoginAPI.login({
      mobile,
      code,
      mobileAreaCode,
    })
      .then(({ token }) => {
        localStorage.setItem('id_token', token);
        return new Promise((resolve) => {
          LoginAPI.getCndidateInfo('').then(({ name }) => {
            if (!name) {
              setOpen(true);
              resolveRef.current = () => resolve('');
            } else {
              resolve('');
            }
          });
        });
      })
      .then(loginRedirect)
      .catch(showErrorMessage)
      .finally(() => setLogining(false));
  }, [getValues, loginRedirect]);

  useEffect(() => {
    const token = localStorage.getItem('id_token');
    if (token) {
      log.capture('Login_Start', {
        message: '开始登录',
        applicationToken: token,
      });
      new Promise((resolve) => {
        LoginAPI.getCndidateInfo('')
          .then(({ name }) => {
            if (!name) {
              setOpen(true);
              resolveRef.current = () => resolve('');
            } else {
              resolve('');
            }
          })
          .catch(() => {
            localStorage.clear();
            window.location.reload();
          });
      })
        .then(loginRedirect)
        .catch(showErrorMessage);
    }
  }, [loginRedirect, navigate]);

  const { mobile, mobileAreaCode = '' } = watch('mobile');

  const area = useMemo(() => {
    return (
      mobileCodes.filter((item) => item.key === mobileAreaCode)?.[0] || {
        area: '中国大陆',
        group: '热门',
        key: 'CN_243',
        label: '中国大陆',
        pyAreaName: 'zhongguodalu',
        val: '86',
        value: '+86',
      }
    );
  }, [mobileAreaCode, mobileCodes]);

  if (token) {
    return (
      <NameDialog
        applicationToken={tempApplicationToken}
        open={open}
        onClose={() => ''}
        onSubmit={() => {
          setOpen(false);
          resolveRef.current?.();
        }}
      />
    );
  }

  return (
    <LanguageLayout>
      <Box
        sx={{
          background: '#F9FAF9',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <form>
          <Stack
            p={4}
            spacing={4}
            component={Paper}
            sx={{ width: 480 }}
            variant="outlined"
          >
            <Typography variant="h5" fontWeight={500}>
              {t('Sign in to continue to  Interview')}
            </Typography>
            <Stack spacing={3}>
              <Controller
                control={control}
                name="mobile"
                render={() => {
                  return (
                    <Layout>
                      <MobileTextField
                        {...mobileTextFieldProps}
                        area={area}
                        // @ts-ignore
                        label={t('Phone Number')}
                        // placeholder={t('Please enter your phone number')}
                        // @ts-ignore
                        sx={{ height: 'auto' }}
                        inputProps={{
                          placeholder: t('Enter your phone number'),
                        }}
                      />
                    </Layout>
                  );
                }}
              />
              <Stack
                direction="row"
                spacing={3}
                height={60}
                justifyContent="space-between"
              >
                <Controller
                  control={control}
                  name="code"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('Verification code')}
                      sx={{ width: 260 }}
                    />
                  )}
                />
                <Stack pt={0.25}>
                  <CodeButton mobile={mobile} mobileAreaCode={mobileAreaCode} />
                </Stack>
              </Stack>
            </Stack>
            <Stack spacing={3}>
              <Button
                size="large"
                disabled={logining}
                onClick={() => handleLogin()}
                variant="contained"
              >
                {t('Sign in / Register')}
              </Button>
              {IS_JIUYEQIAO && (
                <Button
                  size="large"
                  color="inherit"
                  onClick={() => {
                    const redirect = searchParams.get('redirect');
                    navigate(
                      redirect
                        ? `/studentlogin?redirect=${redirect}`
                        : '/studentlogin'
                    );
                  }}
                  variant="outlined"
                >
                  学号登录
                </Button>
              )}
            </Stack>
          </Stack>
        </form>
      </Box>
    </LanguageLayout>
  );
};

export default Login;

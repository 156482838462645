import { Box, Button } from 'fbm-ui';
import { Stack } from 'fbm-ui/lib/mui';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { interviewActions } from './slice';
import { useCallback, useMemo, useRef } from 'react';
import { XiaomianPlayer } from './Xiaomian';
import BackTitle from './BackTitle';
import { useTranslation } from 'react-i18next';
import { log } from '@avocadoui/utils';
import { useInterviewType } from './hooks';

const Welcome = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const { interviewInfo, nextProcess } = useAppSelector(
    (state) => state.interview
  );
  const { interaction, xmSoundUrl } = interviewInfo || {};
  const {
    guidanceText = '',
    guidanceVideo = '',
    guidanceVideoWide = '',
  } = interaction || {};

  const isShowVideo = guidanceVideoWide || guidanceVideo;

  const interviewType = useInterviewType();

  const video = useMemo(
    () => nextProcess.process.videos.find((ele) => ele.type === 2),
    [nextProcess]
  );

  const videos = {
    normal: guidanceVideoWide || guidanceVideo || video?.pcUrl,
    robot: guidanceVideoWide || guidanceVideo || xmSoundUrl,
    virtualHuman:
      interviewInfo?.virtualManMediaWide || interviewInfo?.virtualManMedia,
    newVirtualHuman:
      interviewInfo?.virtualManMediaWide || interviewInfo?.virtualManMedia,
  };

  const handleNext = useCallback(() => {
    log.capture('Welcome_Start', { message: '开始面试' });
    dispatch(interviewActions.setPage(2));
  }, [dispatch]);

  const handleAudioEnded = () => {
    log.capture('Welcome_Audio_Ended', { message: '音频播放结束' });
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
    }
  };

  return (
    <Box
      width={1}
      height="100vh"
      sx={{
        background: '#F9FAF9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        width={1087}
        height={612}
        sx={{
          borderRadius: 2,
          overflow: 'hidden',
        }}
      >
        <video
          ref={videoRef}
          src={videos[interviewType]}
          width="100%"
          height="100%"
          autoPlay
          muted={interviewType === 'newVirtualHuman'}
          loop={interviewType === 'newVirtualHuman'}
          style={{
            display:
              interviewType !== 'robot' || isShowVideo ? 'block' : 'none',
          }}
        />
        {interviewType === 'newVirtualHuman' && (
          <audio ref={audioRef} id="audio" autoPlay onEnded={handleAudioEnded}>
            <source src={xmSoundUrl} type="audio/mp3" />
            您的浏览器不支持 HTML5 音频。
          </audio>
        )}
        {interviewType === 'robot' && !isShowVideo && (
          <XiaomianPlayer action="taking" />
        )}
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 1,
            width: 1,
            alignItems: 'center',
            top: 0,
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignSelf: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Box p={2}>
              <BackTitle title={nextProcess?.position?.name} />
            </Box>
          </Stack>
          <Box width={1}>
            {guidanceText && (
              <Box
                width="calc(100% - 48px)"
                borderRadius={2}
                bgcolor="rgba(255, 255, 255, 0.9)"
                p={1.5}
                fontSize="body1"
                fontWeight={500}
                color="textSecondary"
                mx={3}
                pb={6}
                sx={{ position: 'absolute' }}
                bottom={16}
              >
                {guidanceText}
              </Box>
            )}
            <Stack flexDirection="row" my={3} justifyContent="center">
              <Button onClick={handleNext}>{t("Let's GO!")}</Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Welcome;

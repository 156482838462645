import { Button, IconButton } from 'fbm-ui';
import { Grid, Stack } from 'fbm-ui/lib/mui';
import { ArrowDropDownIcon } from 'fbm-icons';
import { Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { ClickAwayListener } from '@mui/material';

const styles = {
  container: {
    background: '#fff',
    py: 2,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'sticky',
    top: 0,
    zIndex: 100,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
  },
  button: {
    width: 'auto',
    borderRadius: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    border: 'none',
    fontSize: 14,
    fontWeight: 400,
  },
};

const MINI_CATEGORY = 1;
const MINI_POSITION = 1;

const Category = ({ simulatePositions = [], offset = 0 }) => {
  const [expanded, setExpanded] = useState(false);

  const handleScrollToElement = (categoryToken: string) => {
    const targetElement = document.getElementById(categoryToken);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  useEffect(() => {
    setExpanded(false);
  }, [offset]);

  if (
    simulatePositions.length <= MINI_CATEGORY ||
    simulatePositions.reduce((a, v) => {
      return v.positions.length + a;
    }, 0) < MINI_POSITION
  )
    return null;

  return (
    <ClickAwayListener
      onClickAway={() => {
        setExpanded(false);
      }}
    >
      <Stack
        component={Paper}
        variant="outlined"
        width={1}
        direction="row"
        sx={styles.container}
      >
        <Grid
          item
          xs={12}
          container
          spacing={1}
          sx={{
            height: expanded ? 'auto' : 38,
            overflow: 'hidden',
            maxWidth: 1096,
            transition: 'height 0.3s ease-in-out', // 添加过渡动画
          }}
        >
          {simulatePositions?.map(
            ({ category, categoryToken }) =>
              category && (
                <Grid item key={categoryToken}>
                  <Button
                    variant="text"
                    color="inherit"
                    sx={styles.button}
                    onClick={() => handleScrollToElement(categoryToken)}
                  >
                    {category}
                  </Button>
                </Grid>
              )
          )}
        </Grid>
        <IconButton
          onClick={() => setExpanded((prev) => !prev)}
          sx={{
            alignSelf: 'flex-start',
            transition: 'transform 0.3s ease-in-out',
            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        >
          <ArrowDropDownIcon sx={{ color: 'black' }} />
        </IconButton>
      </Stack>
    </ClickAwayListener>
  );
};

export default Category;

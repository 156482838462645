import client from './client';
import { apiCheck } from '../utils/apiCheck';
import { log } from '@avocadoui/utils';
interface LoginParams {
  mobile: string;
  mobileAreaCode: string;
  code: string;
}

export function sendSmsCode(mobile: string, mobileAreaCode: string, ext = {}) {
  return client('mobile/login/sendSmsCode', {
    body: {
      mobile,
      mobileAreaCode,
      ...ext,
    },
  }).then(apiCheck);
}

function login(data: LoginParams) {
  return client(`interview/login`, { body: data })
    .then(apiCheck)
    .then((resp) => {
      log.identify(data.mobile, data);
      return resp;
    });
}

function studentLogin(data: any) {
  return client(`interview/studentLogin`, { body: data })
    .then(apiCheck)
    .then((resp) => {
      log.identify(data.code, data);
      return resp;
    });
}

function authenticationLogin(data: any) {
  return client(`interview/auth/general-authentication`, { body: data })
    .then(apiCheck)
    .then((resp) => {
      log.identify(data.mobile, data);
      return resp;
    });
}

function getCndidateInfo(applicationToken: string, isNeedLogin = true) {
  return client(
    `interview/candidate/info?applicationToken=${applicationToken}`
  ).then((resp) => (isNeedLogin ? apiCheck(resp) : resp.data));
}

interface CndidateParams {
  name: string;
}

function updateCndidateInfo(applicationToken: string, data: CndidateParams) {
  return client(
    `interview/candidate/update?applicationToken=${applicationToken}`,
    { body: data }
  ).then(apiCheck);
}

function getCndidateNextProcess(applicationToken: string) {
  return client(
    `interview/application/nextProcess?applicationToken=${applicationToken}`
  ).then(apiCheck);
}

function getCndidateNextPrepare(positionToken: string) {
  return client(`interview/candidatePrepare?positionToken=${positionToken}`, {
    body: {},
  }).then(apiCheck);
}

function getSimulateSchools() {
  return client(`zjSchools`).then(apiCheck);
}

const RoomAPI = {
  sendSmsCode,
  login,
  studentLogin,
  getCndidateInfo,
  updateCndidateInfo,
  getCndidateNextProcess,
  getCndidateNextPrepare,
  getSimulateSchools,
  authenticationLogin,
};

export default RoomAPI;

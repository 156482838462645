import { Box, Paper, Typography, Button } from 'fbm-ui';
import { Stack } from 'fbm-ui/lib/mui';
import LoginAPI from '../../api/login';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Layout, useMobileTextField } from 'fbm-ui';
import MobileTextField from './MobileTextField';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@avocadoui/components';
import useMobileCodes from '../../hooks/useMobileCodes';
import * as z from 'zod';
import { showErrorMessage } from '../../utils/apiCheck';
import { useNavigate, useParams } from 'react-router-dom';
import CodeButton from './CodeButton';
import NameDialog from './NameDialog';
import { IS_JIUYEQIAO } from '../../oem';
import LanguageLayout from './LanguageLayout';
import { useTranslation } from 'react-i18next';
import { log } from '@avocadoui/utils';

export type FormData = {
  mobile: {
    mobile: string;
    mobileAreaCode?: string;
  };
  code: string;
};

export const schema = z.object({
  mobile: z.object({
    mobile: z.string().min(1),
    mobileAreaCode: z.string(),
  }),
  code: z.string().length(4),
});

const Login = () => {
  const { t } = useTranslation();
  const { interviewToken = '' } = useParams();
  const [open, setOpen] = useState(false);
  const [tempApplicationToken, setTempApplicationToken] = useState('');
  const navigate = useNavigate();
  const resolveRef = useRef<() => void>();
  const [logining, setLogining] = useState(false);

  const { mobileCodes } = useMobileCodes();
  const { mobileTextFieldProps } = useMobileTextField({
    options: mobileCodes,
    defaultMobile: {
      mobile: '',
      mobileAreaCode: '',
    },
    onChange: (val) => {
      setValue('mobile', {
        mobile: val?.mobile,
        mobileAreaCode: getValues().mobile.mobileAreaCode,
      });
    },
    onAreaChange: (val) => {
      setValue('mobile', {
        mobile: getValues().mobile.mobile,
        mobileAreaCode: val,
      });
    },
  });

  const { control, getValues, setValue, watch } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      mobile: {
        mobile: '',
        mobileAreaCode: '',
      },
      code: '',
    },
    resolver: zodResolver(schema),
  });

  const handleLogin = () => {
    setLogining(true);
    const { mobile, mobileAreaCode = '' } = watch('mobile');

    const code = getValues('code');
    LoginAPI.login({
      mobile,
      code,
      mobileAreaCode,
    })
      .then(({ token }) => {
        localStorage.setItem('id_token', token);
        return new Promise((resolve, reject) => {
          LoginAPI.getCndidateNextPrepare(interviewToken)
            .then(({ applicationToken }) => applicationToken)
            .then(LoginAPI.getCndidateInfo)
            .then(({ name, applicationToken }) => {
              if (!name) {
                setOpen(true);
                setTempApplicationToken(applicationToken);
                resolveRef.current = () => resolve({ applicationToken });
              } else {
                resolve({ applicationToken });
              }
            })
            .catch(reject);
        });
      })
      .then(({ applicationToken }) =>
        navigate(`/process/${applicationToken}`, { replace: true })
      )
      .catch(showErrorMessage)
      .finally(() => setLogining(false));
  };

  useEffect(() => {
    const token = localStorage.getItem('id_token');
    if (token) {
      new Promise((resolve) => {
        log.capture('LoginBindPosition_Start', {
          message: '开始登录',
          applicationToken: interviewToken,
        });
        LoginAPI.getCndidateNextPrepare(interviewToken)
          .then(({ applicationToken }) => applicationToken)
          .then(LoginAPI.getCndidateInfo)
          .then(({ name, applicationToken }) => {
            if (!name) {
              setOpen(true);
              resolveRef.current = () => resolve({ applicationToken });
            } else {
              resolve({ applicationToken });
            }
          })
          .catch(() => {
            localStorage.clear();
            window.location.reload();
          });
      })
        .then(({ applicationToken }) =>
          navigate(`/process/${applicationToken}`, { replace: true })
        )
        .catch(showErrorMessage);
    }
  }, [interviewToken, navigate]);

  const { mobile, mobileAreaCode = '' } = getValues('mobile');

  const area = useMemo(() => {
    return mobileCodes.filter((item) => item.key === mobileAreaCode)?.[0];
  }, [mobileAreaCode, mobileCodes]);

  if (open) {
    return (
      <NameDialog
        applicationToken={tempApplicationToken}
        open={open}
        onClose={() => ''}
        onSubmit={() => {
          setOpen(false);
          resolveRef.current?.();
        }}
      />
    );
  }

  if (localStorage.getItem('id_token')) return null;

  return (
    <LanguageLayout>
      <Box
        sx={{
          background: '#F9FAF9',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <form>
          <Stack
            p={4}
            spacing={4}
            component={Paper}
            sx={{ width: 480 }}
            variant="outlined"
          >
            <Typography variant="h5" fontWeight={500}>
              {t('Sign in to continue to  Interview')}
            </Typography>
            <Stack spacing={3}>
              <Controller
                control={control}
                name="mobile"
                render={() => {
                  return (
                    <Layout>
                      <MobileTextField
                        {...mobileTextFieldProps}
                        area={area}
                        // @ts-ignore
                        label={t('Phone Number')}
                        // @ts-ignore
                        sx={{ height: 'auto' }}
                        inputProps={{
                          placeholder: t('Enter your phone number'),
                        }}
                      />
                    </Layout>
                  );
                }}
              />
              <Stack
                direction="row"
                spacing={3}
                height={60}
                justifyContent="space-between"
              >
                <Controller
                  control={control}
                  name="code"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('Verification code')}
                      sx={{ width: 260 }}
                    />
                  )}
                />
                <Stack pt={0.25}>
                  <CodeButton mobile={mobile} mobileAreaCode={mobileAreaCode} />
                </Stack>
              </Stack>
            </Stack>
            <Stack spacing={3}>
              <Button
                size="large"
                disabled={logining}
                onClick={() => handleLogin()}
                variant="contained"
              >
                {t('Sign in / Register')}
              </Button>
              {IS_JIUYEQIAO && (
                <Button
                  size="large"
                  color="inherit"
                  onClick={() => {
                    navigate(`/studentlogin/${interviewToken}`);
                  }}
                  variant="outlined"
                >
                  学号登录
                </Button>
              )}
            </Stack>
          </Stack>
        </form>
        <NameDialog
          applicationToken={tempApplicationToken}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          onSubmit={() => {
            setOpen(false);
            resolveRef.current?.();
          }}
        />
      </Box>
    </LanguageLayout>
  );
};

export default Login;

import { Message } from 'fbm-ui';
import { log } from '@avocadoui/utils';

const MESSAGES = {
  '-2030': {
    zh: '手机号错误',
    en: 'Incorrect phone number',
  },
  '-2001': {
    zh: '参数验证失败',
    en: 'Parameter validation failed',
  },
  '-1001': {
    zh: '系统错误',
    en: 'System Error',
  },
  '-5021': {
    zh: '发送过于频繁',
    en: 'Sending too frequently',
  },
  '-2055': {
    zh: '发送失败，请稍后重试',
    en: 'Sending failed, please try again later',
  },
  '-2054': {
    zh: '验证码失效，请重新获取',
    en: 'Invalid verification code, please try again.',
  },
  '-1002': {
    zh: '操作太频繁了',
    en: 'Operation too frequent',
  },
  '-2016': {
    zh: '验证码错误，请重新输入',
    en: 'Incorrect verification code, please re-enter',
  },
  '-10009': {
    zh: '职位已关闭',
    en: 'Job Closed',
  },
  '-10023': {
    zh: '链接无效，请检查面试链接',
    en: 'Invalid link, please verify the URL',
  },
};

export class APIError extends Error {
  public code: number;
  public apiInfo: { url: string; status: number; parms: Record<string, any> };

  constructor(message, code, apiInfo) {
    super(message);
    this.name = this.constructor.name;
    this.code = code;
    this.apiInfo = apiInfo;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

export const apiCheck = (resp) => {
  const { code, message, data, apiInfo } = resp;
  if (code === -3001) {
    localStorage.removeItem('id_token');
    // window.location.href = '/login';
  }
  if (code !== 0) return Promise.reject(new APIError(message, code, apiInfo));
  const { url, status, parms } = apiInfo || {};
  log.capture('SUCCESS:接口成功', {
    code,
    message,
    url,
    status,
    parms,
    data,
  });
  return data;
};

export const showErrorMessage = (err: APIError) => {
  const lang = sessionStorage.getItem('lang') || 'zh';
  const { url, status, parms } = err.apiInfo || {};
  const message = MESSAGES[err?.code]?.[lang] || err.message;
  Message.error(message);
  log.capture('FAIL:接口错误', { code: err.code, message, url, status, parms });
};
export const suppressErrorMessage = (err: APIError) => console.log(err.message);

import posthog from 'posthog-js';
import Logger from 'eli-fe-track';

let APP_NAME: string;
const ROOM_TOKEN = window.location.pathname.slice(3);

function createConfig(name: string, tags: { [x: string]: string } = {}) {
  return {
    Base: {
      platform: 'web',
      version: '1.0.0',
      appId: name,
      SlsTracker: true,
    },
    SlsTracker: {
      host: 'cn-beijing.log.aliyuncs.com',
      project: 'web-frontend',
      logstore: name,
      time: 10,
      count: 10,
      tags,
    },
  };
}

function toString(o) {
  Object.keys(o).forEach((k) => {
    if (typeof o[k] === 'object') {
      return toString(o[k]);
    }
    o[k] = '' + o[k];
  });
  return o;
}

const log = {
  posthog,
  initPostHog: (token: string, option?: posthog.Config) => {
    posthog.init(token, option);
  },
  initEliTracker: (name) => {
    APP_NAME = name;
    Logger.config(createConfig(name));
    // Logger.sendSysInfo();
  },
  identify: (uniqueId?: string, properties?: posthog.Properties) => {
    posthog.identify(uniqueId, properties);
    Logger.config(
      createConfig(APP_NAME, { userId: `${uniqueId}`, ...toString(properties) })
    );
  },
  capture: (
    eventName: string,
    properties?: posthog.Properties,
    options?: {
      transport: 'XHR' | 'sendBeacon';
    }
  ) => {
    posthog.capture(eventName, properties, options);
    const isError = eventName.includes('FAIL:');
    if (isError) {
      Logger.logLifeCycle(eventName, {
        ...properties,
        level: 'error',
        page: window.location.href,
        ...(ROOM_TOKEN ? { roomToken: ROOM_TOKEN } : {}),
      });
    } else {
      Logger.logLifeCycle(eventName, {
        ...properties,
        page: window.location.href,
        ...(ROOM_TOKEN ? { roomToken: ROOM_TOKEN } : {}),
      });
    }
  },
};

export default log;
